<template>
    <div class="space-modules client-content-padding">
        <div v-if="!loading" class="row">
            <div v-for="(course, index) in data.courses" :key="`space-course-${index}`" class="col-12 col-md-6">
                <SpaceCourse
                    :title="course.title"
                    :description="course.description"
                    :thumbnail="course.thumbnail"
                    :tags="course.tags"
                    :button-label="labels.start_course"
                    :button-action="() => startCourse(course._id)"
                />
            </div>
        </div>
        <SvgLoader v-else :loading="true" />
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: 'ModulesOverviewOfBundle',
    components: {
        SvgLoader: () => import('@/components/Preloaders/SvgLoader'),
        SpaceCourse: () => import('@/components/Cards/SpaceCourse.vue')
    },
    data() {
        return {
            labels: {
                start_course: 'Start course'
            },
            data: {},
            loading: false
        }
    },
    computed: {
        // data() {
        //     let tags = [];

        //     for (let index = 0; index < 5; index++) {
        //         tags.push({
        //             slug: `tag-${index}`,
        //             title: `Tag ${index}`
        //         })
        //     }
            
        //     let result = {
        //         courses: []
        //     };

        //     for (let index = 0; index < 6; index++) {
        //         result.courses.push({
        //             title: `Course #${index}`,
        //             description: 'Nunc placerat ante quis felis efficitur, et commodo nisl dapibus. Morbi varius urna quis magna egestas ultricies. Suspendisse ullamcorper congue tincidunt.',
        //             thumbnail: require('@/assets/no-image.png'),
        //             _id:  index,
        //             tags: tags
        //         });
        //     }

        //     return result;
        // }
    },
    methods: {
        ...mapActions('notification', ['addNotification']),
        ...mapActions('space', ['getAccountSpaceMbundles']),
        getSpaceData() {
            this.loading = true;

            this.getAccountSpaceMbundles().then( res => {
                if (res.success) {
                    console.log('Got res:', res);
                    this.data = res.data;
                }

                if (res.error) {
                    this.addNotification( { variant: 'danger', msg: res.error, labels: this.labels })
                }

                this.loading = false;
            } ).catch( err => console.log( err ) );
        },
        startCourse(id) {
            console.log(`Start course ${id}`);
            this.$router.push({
                name: 'bundle',
                params: {
                    monetization_bundle_id: id
                }
            })
        }
    },
    mounted() {
        this.getSpaceData();
    }
}
</script>

<style scoped lang="scss">
    @import "@/styles/common";

    .space {
        &-modules {
            margin-top: -30px;
            @include client-margin;
        }

        &-course {
            margin-top: 30px;
        }
    }
</style>